import { API, API_BLOB /*, API_ZAP */ } from "./API";
//import { Capacitor, Plugins, FilesystemDirectory } from '@capacitor/core';
//import store_site from "../store/store_site";
//import store_usuario from "../store/store_usuario";

const OpcaoVendaGet = async (body) => {
    const resp = await API.get(`opcaovenda?cod_imovel=${body}`);
    const { data } = resp;
    return data.result;
};

const QualificacaoPost = async (body) => {
    const resp = await API.post(`qualificacao`, JSON.stringify(body));
    const { data } = resp;
    //console.log(data);
    return data;
};

const ReservaPost = async (body) => {
    const resp = await API.post(`reserva`, JSON.stringify(body));
    const { data } = resp;
    //console.log(data);
    return data;
};

const OrcamentoPost = async (body) => {
    const resp = await API.post(`orcamento`, JSON.stringify(body));
    const { data } = resp;
    //console.log(data);
    return data;
};

const PropostaPost = async (body) => {
    const resp = await API.post(`proposta`, JSON.stringify(body));
    const { data } = resp;
    return data;
};

const ImovelPut = async (body) => {
    const resp = await API.put(`imovel/${body.cod_imovel}`, JSON.stringify(body));
    const { data } = resp;
    return data;
};

export {
    ReservaPost,
    OrcamentoPost,
    QualificacaoPost,
    PropostaPost,    
    OpcaoVendaGet,
    ImovelPut,
}
