import { formatNumber,
		 to_Number,
		 calculaFinanciamento,
		 formatPrice} from "./funcoes"
import { HyperFormula } from 'hyperformula';
import ptPT from "hyperformula/es/i18n/languages/ptPT";
// import readXlsxFile from 'read-excel-file';
//import json_calculo  from '../../public/calculo.json';
import { read, utils } from 'xlsx';

var array_planilha = [[],[],[],[],[],[],[],[],[],[],[],[],[],[],[],[],[],[],[],[],[],[],[],[],[],[]];
var workbook;
var hf;

// register language
try {
	//HyperFormula.registerLanguage(ptPT.langCode, ptPT);
}
catch (err) {
}	

// define the options
const options = {
	//language: "ptPT",
	licenseKey: 'gpl-v3',
	//precisionRounding: 4,
	//nullDate: { year: 1900, month: 1, day: 1 },
	functionArgSeparator: ';',
	evaluateNullToZero: true,
	//decimalSeparator: ',',
	//thousandSeparator: '.',
};

hf = HyperFormula.buildEmpty(options);
// Excel compatibility
//hf.addNamedExpression("FALSE", "=FALSE()");
//hf.addNamedExpression("TRUE", "=TRUE()");
// Prevent calculations and evaluations while building
//hf.suspendEvaluation();

var Retorno_Vazio = {"FORMA_PAGTO"  : null,
					 "ENTRADA_TOTAL": 	{
						editavel				: true,
						visivel					: true,
						valor_minimo			: null,
						cliente_entrada_total	: null,
						entrada_total_calc		: null,
					},
					"ENTRADA_FORMA_PGTO":{
							editavel				: true,
							visivel					: true,
							itens_formapagto		: []
						},
					"ENTRADA_SINAL":		{
							editavel				: true,
							visivel					: false,
							valor_minimo			: null,
							entrada_sinal			: null,
							cliente_entrada_sinal 	: null,
						},
					"ENTRADA_RESTANTE":		{
							editavel				: true,
							visivel					: true,
							itens_formapagto		: [],
							itens_restante			: null,
							descricao_restante		: null,
							entrada_valor_restante	: null,
							item_selecionado		: {}
						},
					"SALDO_RESTANTE":	{
							valor					: null,
						},
					"PARCELAS": {
							itens_formapagto		: [],
							item_selecionado		: {},
							opcoes_dia_vencto   : []
					},
					"TOTAIS":  {
							editavel						: false,
							visivel							: true,
							area_metro					: null,
							preco_metro					: null,
							preco_total					:	null,
							preco_desconto			:	null,
							preco_final					: null,
							entrada							: null,
							saldo_restante			: null,
							total_contrato			: null,
						}
				}

var Retorno = {	"FORMA_PAGTO"  : null,
				"ENTRADA_TOTAL": 	{
						editavel				: true,
						visivel					: true,
						valor_minimo			: null,
						cliente_entrada_total	: null,
						entrada_total_calc		: null,
					},
				"ENTRADA_FORMA_PGTO":{
						editavel				: true,
						visivel					: true,
						itens_formapagto		: []
					},
				"ENTRADA_SINAL":		{
						editavel				: true,
						visivel					: false,
						valor_minimo			: null,
						entrada_sinal			: null,
						cliente_entrada_sinal 	: null,
					},
				"ENTRADA_RESTANTE":		{
						editavel				: true,
						visivel					: true,
						itens_formapagto		: [],
						itens_restante			: null,
						descricao_restante		: null,
						entrada_valor_restante	: null,
						item_selecionado		: {}
					},
				"SALDO_RESTANTE":	{
						valor					: null,
					},
				"PARCELAS": {
							itens_formapagto		: [],
							item_selecionado		: {}
					},
				"TOTAIS":  {
						editavel						: false,
						visivel							: true,
						area_metro					: null,
						preco_metro					: null,
						preco_total					:	null,
						preco_desconto			:	null,
						preco_final					: null,
						entrada							: null,
						saldo_restante			: null,
						total_contrato			: null,
					}
			}

const retornaPlanoPossibilidades = (value) => {
	return Retorno
}

const setOrcamentoParcelas = (value) => {
	let array = [];

	// Plano a vista
	if (value.plano.planovenda_entrada_porc === 100) {
		array.push({
			ordem: 1,
			tipo: "SINAL",
			saldo_calcular: 0,
			saldo_restante: 0,
			valor: value.totalEntrada,
			taxa: value.plano.planovenda_entrada_reajuste,
			quantidade: 1,
		});
	} else {
		if (value.entradaSinal) {
			array.push({
				ordem: 1,
				tipo: "SINAL",
				saldo_calcular: 0,
				saldo_restante: 0,
				valor: 99999, //value.entradaSinal,
				taxa: 0,
				quantidade: 1,
			});
		}

		if (value.entradaQtde > 0) {
			array.push({
				ordem: 2,
				tipo: "ENTRADA",
				saldo_calcular: 0,
				saldo_restante: 0,
				valor: value.entradaParcelada,
				taxa: value.plano.planovenda_entrada_reajuste,
				quantidade: value.entradaQtde,
			});
		}

		if (value.parcelaQtde > 0) {
			array.push({
				ordem: 3,
				tipo: "PARCELA",
				saldo_calcular: 0,
				saldo_restante: 0,
				valor: value.parcelaValor,
				taxa: value.plano.planovenda_parcela_reajuste,
				quantidade: value.parcelaQtde,
			});
		}
	}

	return array;
};

const generateOrcamento = (listOrcamento, contato, imovel, proposta) => {
	let newOrcamento = {};
	let planos = [];

	listOrcamento.forEach((value, index) => {
		let parcelas = [];
		parcelas.push(...setOrcamentoParcelas(value));
    //console.log('value.saldoRestante', value.saldoRestante)
		planos.push(
			...[
				{
					cod_planovenda: value.plano.cod_planovenda,
					imovel_preco: imovel.preco_total,
					imovel_preco_desconto: imovel.preco_desconto,
					imovel_preco_final: imovel.preco_final,
					entrada_total: value.totalEntrada,
					entrada_sinal_obrigatorio: value.plano.planovenda_entrada_sinal_obr,
					entrada_sinal_valor: value.entradaSinal,
					entrada_minimo_permitido: value.plano.calc_entrada_minima,
					saldo_restante: value.saldoRestante,
					parcelas,
				},
			]
		);
	});

	newOrcamento = {
		cod_contato: contato.cod_contato,
		cod_imovel: imovel.cod_imovel,
		data_entrada_inicial: proposta ? proposta.data_entrada_inicial : null,
		data_parcela_inicial: proposta ? proposta.data_parcela_inicial : null,
		planos,
	};

	return newOrcamento;
};

// Retorna as possibilidades de pagamentos de acordo com a configuração do Plano de Pagamento Escolhido
// const retornaPlanoPossibilidades2 = (value) => {

// 	let ol_Return = {};
// 	let lf_entrada_total_minimo = 0;

// 	// Valor Minimo da Entrada para o Plano Escolhido
// 	lf_entrada_total_minimo = calculaEntradaMinima(	value.plano,
// 													value.preco_total);
// 	if (!value.cliente_entrada_total)
// 		value.cliente_entrada_total = lf_entrada_total_minimo;
// 	else
// 		value.cliente_entrada_total = parseFloat(value.cliente_entrada_total);

// 	// Plano a vista (100%)
// 	if (value.plano.planovenda_entrada_porc === 100) {
// 		ol_Return = {"ENTRADA_TOTAL": {	editavel: false,
// 										visivel: true,
// 										valor_minimo: lf_entrada_total_minimo,
// 										cliente_entrada_total: value.cliente_entrada_total,
// 									   },
// 				   "TOTAIS": 		  {	editavel: false,
// 										visivel: true,
// 										preco_unidade	: value.preco_total,
// 										entrada			: value.cliente_entrada_total,
// 										saldo_restante	: 0,
// 									}
// 					}
// 	}
// 	// Plano parcelado
// 	else if (value.plano.planovenda_entrada_porc < 100) {
// 		if (!value.cliente_entrada_sinal)
// 			value.cliente_entrada_sinal = null;
// 		else
// 			value.cliente_entrada_sinal = parseFloat(value.cliente_entrada_sinal);

// 		if (!value.cliente_entrada_restante_qtde)
// 			value.cliente_entrada_restante_qtde = 0;

// 		var ol_calculaEntradaDivisao = calculaValores(	value.plano,
// 														value.preco_total,
// 														value.cliente_entrada_total,
// 														value.plano.planovenda_entrada_quant,
// 														value.cliente_entrada_sinal,
// 														value.cliente_entrada_restante_qtde
// 													)
// 		// Corrige o valor de entrada do sinal
// 		if (value.cliente_entrada_sinal &&
// 			ol_calculaEntradaDivisao.entrada_sinal &&
// 			value.cliente_entrada_sinal != ol_calculaEntradaDivisao.entrada_sinal) {
// 			//value.cliente_entrada_sinal 				= ol_calculaEntradaDivisao.entrada_sinal;
// 			//value.cliente_entrada_total 				= parseFloat(value.cliente_entrada_total);
// 			ol_calculaEntradaDivisao.entrada_total_calc = value.cliente_entrada_sinal + (ol_calculaEntradaDivisao.entrada_qtde_restante * ol_calculaEntradaDivisao.entrada_valor_restante);
// 		}
// console.log('XXXXXXXXXX', value.cliente_entrada_sinal, ol_calculaEntradaDivisao.entrada_sinal, ol_calculaEntradaDivisao.entrada_sinal_minimo);

// 		ol_Return = {	"ENTRADA_TOTAL": 	{
// 												editavel: true,
// 												visivel: true,
// 												valor_minimo: lf_entrada_total_minimo,
// 												cliente_entrada_total: value.cliente_entrada_total,
// 												entrada_total_calc: ol_calculaEntradaDivisao.entrada_total_calc,
// 									   		},
// 					   	"ENTRADA_FORMA_PGTO":{
// 												editavel: true,
// 												visivel: true,
// 												itens_formapagto: ol_calculaEntradaDivisao.itens_formapagto
// 						   					},
// 					   "ENTRADA_SINAL":		{
// 												editavel: true,
// 												visivel: false,
// 												valor_minimo: ol_calculaEntradaDivisao.entrada_sinal_minimo,
// 												entrada_sinal: ol_calculaEntradaDivisao.entrada_sinal,
// 												cliente_entrada_sinal : value.cliente_entrada_sinal,
// 						   					},
// 					   "ENTRADA_RESTANTE":		{
// 												editavel: true,
// 												visivel: true,
// 												itens_restante: ol_calculaEntradaDivisao.itens_restante,
// 												descricao_restante: ol_calculaEntradaDivisao.descricao_restante,
// 												entrada_valor_restante: ol_calculaEntradaDivisao.entrada_valor_restante,
// 												entrada_parcelas_restante: 0,
// 						   					},
// 					   "SALDO_RESTANTE":	{
// 						   						valor: ol_calculaEntradaDivisao.saldo_restante,
// 					   						},
// 					   "PARCELAS"		:	ol_calculaEntradaDivisao.parcelas,

// 					   "TOTAIS"			:  {	editavel: false,
// 												visivel: true,
// 												preco_unidade	: value.preco_total,
// 												entrada			: ol_calculaEntradaDivisao.entrada_total_calc,
// 												saldo_restante	: ol_calculaEntradaDivisao.saldo_restante,
// 											}

// 					}
// 	}

// 	else
// 	{
// 		if (value.plano.planovenda_entrada_porc === 100) {

// 		}
// 	}
// 	//console.log("retornaPlanoPossibilidades====")
// 	return ol_Return;

// }

// const calculaValores = (plano,
// 						preco_total,
// 						vl_entrada_total,
// 						qtde_entrada_total,
// 						vl_entrada_sinal,
// 						qtde_entrada_restante
// 						) => {

// 	var i;
// 	var taxa_entrada  		= plano.planovenda_entrada_reajuste;
// 	var taxa_parcelas 		= plano.planovenda_parcela_reajuste;
// 	var li_qtde_parcelas 	= plano.planovenda_parcela_quant;

// 	// Calcula o Total da Entrada
// 	let entrada_total = to_Number(calculaFinanciamento(vl_entrada_total, taxa_entrada, qtde_entrada_total));

// 	// ===== ENTRADA_FORMA_PGTO =========================================
// 	var itens_formapagto = ['Somente Sinal'];
// 	for (i = 1; i < qtde_entrada_total; i++) {
// 		itens_formapagto.push("Sinal + " + i);
// 	}

// 	// ===== ENTRADA_SINAL ===============================================
// 	// Calcula o Valor do Sinal da Entrada já dividido
// 	let entrada_sinal;
// 	let entrada_sinal_minimo = 0;
// 	if (qtde_entrada_restante == 0) { // Passou Sinal Somente
// 		//console.log("1 - ZZZZZZZZZZZZZZZ", vl_entrada_total, vl_entrada_sinal, qtde_entrada_restante);
// 		entrada_sinal = vl_entrada_total;
// 		entrada_sinal_minimo = entrada_sinal;
// 		console.log('1')
// 	}
// 	else if (qtde_entrada_restante > 0) { // Passou Restante do Sinal
// 		if (vl_entrada_sinal == null) {
// 			entrada_sinal = parseFloat(calculaFinanciamento(vl_entrada_total, 0, qtde_entrada_restante + 1));
// 			entrada_sinal_minimo = entrada_sinal;
// 			//console.log("2 - ZZZZZZZZZZZZZZZ", vl_entrada_total, vl_entrada_sinal, qtde_entrada_restante, entrada_sinal);
// 		}
// 		else {
// 			//console.log("3 - ZZZZZZZZZZZZZZZ", vl_entrada_total, vl_entrada_sinal, entrada_total_calc);
// 			entrada_sinal = vl_entrada_sinal;
// 			entrada_sinal_minimo = parseFloat(calculaFinanciamento(vl_entrada_total, 0, qtde_entrada_restante + 1));
// 		}
// 	}

// 	// ===== ENTRADA_RESTANTE ============================================
// 	// calcula parcelas restantes da entrada
// 	var vl_entrada_restante_total = vl_entrada_total - entrada_sinal;
// 	var entrada_valor_restante;
// 	if (qtde_entrada_restante == 0)
// 		qtde_entrada_restante = 1;
// 	var entrada_valor_restante_sem_juros = parseFloat(calculaFinanciamento(vl_entrada_restante_total, 0, qtde_entrada_restante));
// 	var entrada_valor_restante_com_juros = parseFloat(calculaFinanciamento(vl_entrada_restante_total, taxa_entrada, qtde_entrada_restante));
// 	var entrada_juros = (qtde_entrada_restante * entrada_valor_restante_com_juros) - (qtde_entrada_restante * entrada_valor_restante_sem_juros)
// 	if (plano.planovenda_entrada_reajuste > 0) {
// 		entrada_valor_restante = entrada_valor_restante_com_juros;
// 		//console.log('SIM', entrada_valor_restante);
// 	}
// 	else {
// 		entrada_valor_restante = entrada_valor_restante_sem_juros;
// 		//console.log('NAO', entrada_valor_restante);
// 	}

// 	var	entrada_total_calc
// 	if (qtde_entrada_restante > 0) {
// 		entrada_total_calc = to_Number(entrada_sinal + (qtde_entrada_restante * entrada_valor_restante));
// 	}
// 	else {
// 		entrada_total_calc = entrada_sinal;
// 	}

// 	let tot_entrada_Aux = to_Number(entrada_sinal_minimo + (qtde_entrada_restante * entrada_valor_restante))
// 	console.log("3 - ZZZZZZZZZZZZZZZ", entrada_sinal_minimo, entrada_total_calc, vl_entrada_total, tot_entrada_Aux, to_Number(vl_entrada_total/(qtde_entrada_restante+1)));
// 	if (tot_entrada_Aux < vl_entrada_total) {
// 		entrada_sinal_minimo  = to_Number(entrada_sinal_minimo + (vl_entrada_total - tot_entrada_Aux))
// 		console.log("B1", tot_entrada_Aux)
// 	}
// 	else if (tot_entrada_Aux > vl_entrada_total) {
// 		entrada_sinal_minimo  = to_Number(entrada_sinal_minimo + (tot_entrada_Aux - vl_entrada_total))
// 		console.log("B2", tot_entrada_Aux)
// 	}
// 	// Corrigindo centavos para
// 	if (plano.planovenda_entrada_reajuste <= 0) {
// 		if (entrada_total_calc < vl_entrada_total) {
// 			entrada_sinal        = to_Number(entrada_sinal + (vl_entrada_total - entrada_total_calc));
// 			console.log('1111111111111', entrada_sinal_minimo)
// 		}
// 		if (entrada_total_calc > vl_entrada_total) {
// 			entrada_sinal 		 = to_Number(entrada_sinal - (entrada_total_calc - vl_entrada_total));
// 			console.log('2222222222222', entrada_sinal_minimo)
// 		}
// 	}

// 	if (qtde_entrada_restante > 0) {
// 		entrada_total_calc = to_Number(entrada_sinal + (qtde_entrada_restante * entrada_valor_restante));
// 	}
// 	else {
// 		entrada_total_calc = entrada_sinal;
// 	}

// 	let descricao_restante = `${qtde_entrada_restante} x ${formatNumber(entrada_valor_restante)}`;

// 	// ===== PARCELAS ==========================================
// 	// calcula saldo restante para calculos das parcelas, já calculando a entrada com juros embutido
// 	//var saldo_restante = to_Number(preco_total - (entrada_sinal + (qtde_entrada_restante * entrada_valor_restante_sem_juros)));
// 	var saldo_restante = to_Number(preco_total - vl_entrada_total);

// 	// Verifica se aplica formula dos juros no saldo das parcelas, referente ao prazo que ficou sem receber parcelas (ou durante a entrada)
// 	if (qtde_entrada_restante > 0) {
// 		if (plano.planovenda_parcela_saldo_tipo == 'TABELA PRICE') {
// 			let ld_PriceComJuros = parseFloat(calculaFinanciamento(to_Number(preco_total - vl_entrada_total), taxa_parcelas, qtde_entrada_total)) * qtde_entrada_total;
// 			let ld_PriceSemJuros = parseFloat(calculaFinanciamento(to_Number(preco_total - vl_entrada_total), 0, qtde_entrada_total)) * qtde_entrada_total;
// 			saldo_restante = to_Number(ld_PriceComJuros - ld_PriceSemJuros);

// 			//saldo_restante = saldo_restante + entrada_juros;
// 			//console.log("ld_PriceComJuros", ld_PriceComJuros, "ld_PriceSemJuros", ld_PriceSemJuros, "saldo_restante", saldo_restante)
// 		}
// 		else if (plano.planovenda_parcela_saldo_tipo == 'VALOR FUTURO DE INVESTIMENTO FINANCEIRO') {
// 			saldo_restante = (to_Number(preco_total - vl_entrada_total)) * Math.pow((1 + (taxa_parcelas / 100)), qtde_entrada_total);
// 			//console.log("preco_total - vl_entrada_restante_total", to_Number(preco_total - vl_entrada_total), "taxa_parcelas", taxa_parcelas, "qtde_entrada_total", qtde_entrada_total, " entrada_valor_restante", saldo_restante);
// 		}
// 	}

// 	var parcelas = []
// 	var lf_parcelas_financiamentos = 0.00;
// 	if (li_qtde_parcelas == 0) {
// 		parcelas.push({	parcelas_qtde : 1,
// 						parcelas_valor: preco_total,
// 						parcelas_valor_total: to_Number(preco_total),
// 						parcelas_descricao: 1 + " x R$ " + formatPrice(preco_total)});
// 		//console.log("====================== 111", parcelas)
// 	}
// 	else  {
// 		for (i = 1; i <= li_qtde_parcelas; i++) {
// 			lf_parcelas_financiamentos = parseFloat(calculaFinanciamento(saldo_restante, taxa_parcelas, i))
// 			parcelas.push({	parcelas_qtde : i,
// 							parcelas_valor: lf_parcelas_financiamentos,
// 							parcelas_valor_total: to_Number(i * lf_parcelas_financiamentos),
// 							parcelas_descricao: i + " x R$ " + formatPrice(lf_parcelas_financiamentos)});
// 		}
// 		//console.log("====================== 222", parcelas)
// 	}

// 	// console.log("3 - =========================");
// 	// console.log("3 - preco_total", preco_total);
// 	// console.log("3 - vl_entrada_sinal", vl_entrada_sinal);
// 	// console.log("3 - vl_entrada_restante_total", vl_entrada_restante_total);
// 	// console.log("3 - entrada_valor_restante_sem_juros", entrada_valor_restante_sem_juros);
// 	// console.log("3 - entrada_valor_restante_com_juros", entrada_valor_restante_com_juros);
// 	// console.log("3 - entrada_total_calc", entrada_total_calc);
// 	// console.log("3 - entrada_juros", entrada_juros);
// 	// console.log("3 - entrada_sinal", entrada_sinal);
// 	// console.log("3 - qtde_entrada_restante", qtde_entrada_restante);
// 	// console.log("3 - entrada_valor_restante", entrada_valor_restante);
// 	// console.log("3 - taxa_parcelas", taxa_parcelas);
// 	// console.log("3 - li_qtde_parcelas", li_qtde_parcelas);
// 	// console.log("3 - saldo_restante", saldo_restante);
// 	//console.log("3 - entrada_sinal_minimo", entrada_sinal_minimo);
// 	return {
// 		entrada_total		   	: entrada_total <= 0 ? 0 : entrada_total,
// 		entrada_total_calc   	: entrada_total_calc <= 0 ? 0 : entrada_total_calc,
// 		entrada_sinal		   	: entrada_sinal <= 0 ? 0 : entrada_sinal,
// 		entrada_sinal_minimo   	: entrada_sinal_minimo <= 0 ? 0 : entrada_sinal_minimo,
// 		entrada_qtde_restante  	: qtde_entrada_restante,
// 		entrada_valor_restante 	: entrada_valor_restante,
// 		entrada_juros			: entrada_juros,
// 		//descricao_entrada	   	: entrada_total <= 0 ? formatNumber(0) : descricao_entrada,
// 		itens_formapagto	   	: itens_formapagto,
// 		//itens_restante		   	: itens_restante,
// 		descricao_restante	   	: vl_entrada_restante_total <= 0 ? formatNumber(0) : descricao_restante,
// 		saldo_restante			: saldo_restante,

// 		parcelas				: parcelas,

// 		preco_unidade	: plano.preco_total,
// 		entrada			: entrada_total_calc,
// 		saldo_restante	: saldo_restante,

// 	};

// };

//////// ========================================================================================================================================================
//////// ========================================================================================================================================================
//////// ========================================================================================================================================================
//////// ========================================================================================================================================================
//////// ========================================================================================================================================================
//////// ========================================================================================================================================================

// Abre o Plano
const C1_calculaPlano = async (plano) => {
	var itens_formapagto = ['Somente Sinal'];
	if (plano.planovenda_entrada_quant > 1)
		itens_formapagto.push('Sinal + Restante');
	Retorno = {...Retorno_Vazio};
	Retorno.FORMA_PAGTO = plano.planovenda_entrada_porc;
	Retorno.ENTRADA_FORMA_PGTO.itens_formapagto	= [...itens_formapagto];
	
	// Abrindo Planilha para calculos especiais ---------------------------
	if (plano.planovenda_formula) {
		//console.log('PLANO VENDA FORMULA 1 .......', b64toBlob)
		const b64toBlob = (base64,
											type = 'application/octet-stream') => fetch(`data:${type};base64,${base64}`)
											.then(res => res.blob())
		workbook = read(plano.planovenda_formula);
		//console.log('PLANO VENDA FORMULA 4 .......', workbook);

		// Build HyperFormula sheets
		for (const name of workbook.SheetNames) {
			//console.log("loop 1.1 ::::: ");
			const sheet = workbook.Sheets[name];
			//console.log("loop 1.2 ::::: ", sheet);
			const hf_sheet = hf.addSheet(name);
			//console.log("loop 1.3 ::::: ");
			const hf_sheetId = hf.getSheetId(hf_sheet);
			//console.log("loop 1.3 ::::: ", hf_sheetId);
			for (const [key, cell] of Object.entries(sheet)) {
				//for (let index = 0; index < Object.entries(sheet).length; index++) {
					//const elem = Object.entries(sheet);
					//const [key, cell] of Object.entries(sheet)
					//console.log("cell == ::: ", elem);
				
				if (key.startsWith("!")) continue; // skip meta data
				
				const address = hf.simpleCellAddressFromString(key, hf_sheetId);
				if (cell.f) {
					const value = `=${cell.f}`;
					//hf.setCellContents(address, value);
					//console.log("cell F ::: ", address, value);
					if (!array_planilha[address.row] || array_planilha[address.row] == [])
						array_planilha[address.row] = [];
					array_planilha[address.row][address.col] = String(value).replaceAll(',',';');
				} else {
					const value = cell.v;
					//hf.setCellContents(address, value);
					//console.log("cell V ::: ", address, value);
					if (!array_planilha[address.row] || array_planilha[address.row] == [])
						array_planilha[address.row] = [];
					array_planilha[address.row][address.col] = String(value) //.replaceAll(',','').replaceAll('%','');
				}
			}
		}
		//console.log("ARRAYS 1 =========== ", json_calculo)
		//console.log("ARRAYS 2 =========== ", array_planilha)
	}

	return Retorno;
}

// Calcula a Entrada Minima de Acordo com o Plano
const C2_calculaEntradaMinimaInicial = (plano, imovel) => {
	Retorno.ENTRADA_TOTAL.valor_minimo 					= to_Number((plano.planovenda_entrada_porc/100) * imovel.preco_final);
	Retorno.ENTRADA_TOTAL.cliente_entrada_total	= to_Number((plano.planovenda_entrada_porc/100) * imovel.preco_final);
	Retorno.ENTRADA_TOTAL.entrada_total_calc		= to_Number((plano.planovenda_entrada_porc/100) * imovel.preco_final);
	Retorno.TOTAIS.preco_total		  = imovel.preco_total;
	Retorno.TOTAIS.preco_desconto		= imovel.preco_desconto;
	Retorno.TOTAIS.preco_final		  = imovel.preco_final;

	if (plano.planovenda_entrada_porc === 100) {
		Retorno.ENTRADA_TOTAL.editavel	= false;
		Retorno.ENTRADA_TOTAL.visivel		= true;
		Retorno.TOTAIS.editavel					= false;
		Retorno.TOTAIS.visivel					= true;
		Retorno.TOTAIS.entrada					= to_Number((plano.planovenda_entrada_porc/100) * imovel.preco_final);
		Retorno.TOTAIS.saldo_restante		= 0;
		Retorno.FORMA_PAGTO							= plano.planovenda_entrada_porc;
	}
	else {
		Retorno.ENTRADA_TOTAL.editavel	= true;
		Retorno.ENTRADA_TOTAL.visivel	= true;
	}
	//console.log("C2_calculaEntradaMinimaInicial", Retorno.TOTAIS.saldo_restante);
	if (plano.planovenda_formula)
		hyper1(	
						imovel,
						plano.planovenda_parcela_reajuste,
						Retorno.ENTRADA_TOTAL.cliente_entrada_total,
						1, // Verificar este valor para planos sem entrada
						plano.planovenda_parcela_quant
					);
	return Retorno;
};

const C3_calculaClienteEntradaTotal = (plano, imovel, entrada_total_usuario) => {
	Retorno.ENTRADA_TOTAL.cliente_entrada_total	= to_Number(entrada_total_usuario);
	Retorno.ENTRADA_TOTAL.entrada_total_calc	= to_Number(entrada_total_usuario);
	if (plano.planovenda_formula)
		hyper1(	imovel,
						plano.planovenda_parcela_reajuste,
						Retorno.ENTRADA_TOTAL.cliente_entrada_total,
						1, // Verificar este valor para planos sem entrada
						Retorno.PARCELAS.item_selecionado.parcelas_qtde
					);

	return Retorno;
};

const C4_calculaEntradaSinalInicial = (plano) => {
	let entrada_sinal;
	let entrada_sinal_minimo;
	entrada_sinal			= to_Number((Retorno.ENTRADA_TOTAL.cliente_entrada_total / (plano.planovenda_entrada_quant)));
	entrada_sinal_minimo 	= entrada_sinal;
	Retorno.ENTRADA_SINAL.valor_minimo 			= entrada_sinal_minimo;
	if (plano.planovenda_entrada_valor_min && plano.planovenda_entrada_valor_min > 0)
		Retorno.ENTRADA_SINAL.valor_minimo = plano.planovenda_entrada_valor_min;
	Retorno.ENTRADA_SINAL.entrada_sinal			= entrada_sinal_minimo;
	Retorno.ENTRADA_SINAL.cliente_entrada_sinal = entrada_sinal_minimo;
	return Retorno;
};

const C5_calculaEntradaParcelasRestantesItens = (entrada_total, plano, sinal, imovel) => {
	let parcelas = []
	let lf_parcelas_financiamentos 	= 0.00;
	let taxa_entradas 				= plano.planovenda_entrada_reajuste;
	let li_qtde_parcelas 			= plano.planovenda_entrada_quant-1;
	let saldo_restante				= entrada_total - sinal;
	//console.log('C5_calculaEntradaParcelasRestantesItens', saldo_restante)
	if (li_qtde_parcelas == 0) {
		parcelas.push({	parcelas_qtde : 1,
						parcelas_valor: imovel.preco_final,
						parcelas_valor_total: to_Number(imovel.preco_final),
						parcelas_descricao: 1 + " x R$ " + formatPrice(imovel.preco_final)});
	}
	else  {
		for (let i = 1; i <= li_qtde_parcelas; i++) {
			lf_parcelas_financiamentos = parseFloat(calculaFinanciamento(saldo_restante, taxa_entradas, i))
			if (!plano.planovenda_entrada_valor_min ||
				 (plano.planovenda_entrada_valor_min && plano.planovenda_entrada_valor_min > 0 && lf_parcelas_financiamentos >= plano.planovenda_entrada_valor_min))
				parcelas.push({	parcelas_qtde : i,
								parcelas_valor: lf_parcelas_financiamentos,
								parcelas_valor_total: to_Number(i * lf_parcelas_financiamentos),
								parcelas_descricao: i + " x R$ " + formatPrice(lf_parcelas_financiamentos)});
		}
	}
	Retorno.ENTRADA_RESTANTE.itens_formapagto = parcelas;
	Retorno.ENTRADA_RESTANTE.item_selecionado = parcelas[parcelas.length-1];
	return Retorno;
};

const C6_calculaEntradaParcelasRestantesInicial = (entrada_total, plano, qtde_entradas_restantes) => {
	let vl_parcelas_restantes;
	let taxa_entrada  		= plano.planovenda_entrada_reajuste;
	let entrada_sinal       = Retorno.ENTRADA_SINAL.cliente_entrada_sinal;
	if (taxa_entrada && taxa_entrada > 0) {
	    const sinal = to_Number((entrada_total / (qtde_entradas_restantes+1)));
		vl_parcelas_restantes = to_Number(calculaFinanciamento(entrada_total - sinal, taxa_entrada, qtde_entradas_restantes));
	}
	else {
		vl_parcelas_restantes = to_Number(((entrada_total - entrada_sinal) / (qtde_entradas_restantes)));

		// Corrigindo centavos para
		if (qtde_entradas_restantes && qtde_entradas_restantes > 0) {
			let entrada_total_calc 	= to_Number(entrada_sinal + (vl_parcelas_restantes * qtde_entradas_restantes));
			let entrada_sinal_calc  = 0;
			if (plano.planovenda_entrada_reajuste <= 0) {
				if (entrada_total > entrada_total_calc) {
					entrada_sinal_calc   = entrada_sinal + (to_Number(entrada_total - entrada_total_calc));
					Retorno.ENTRADA_SINAL.valor_minimo 			= entrada_sinal_calc;
					Retorno.ENTRADA_SINAL.entrada_sinal			= entrada_sinal_calc;
					Retorno.ENTRADA_SINAL.cliente_entrada_sinal = entrada_sinal_calc;
				}
				if (entrada_total_calc > entrada_total) {
					entrada_sinal_calc	 = entrada_sinal - (to_Number(entrada_total_calc - entrada_total));
					Retorno.ENTRADA_SINAL.valor_minimo 			= entrada_sinal_calc;
					Retorno.ENTRADA_SINAL.entrada_sinal			= entrada_sinal_calc;
					Retorno.ENTRADA_SINAL.cliente_entrada_sinal = entrada_sinal_calc;
				}
			}
		}

	}
	Retorno.ENTRADA_RESTANTE.entrada_parcelas_restante	= qtde_entradas_restantes,
	Retorno.ENTRADA_RESTANTE.entrada_valor_restante 	= vl_parcelas_restantes;
	Retorno.ENTRADA_RESTANTE.descricao_restante 		= `${qtde_entradas_restantes} x ${formatNumber(vl_parcelas_restantes)}`;
	return Retorno;
};

const C7_calculaEntradaParcelasRestantesCliente = (entrada_total, sinal_cliente, plano, qtde_parcelas_restantes) => {
	let vl_parcelas_restantes;
	var taxa_entrada  		= plano.planovenda_entrada_reajuste;
	if (taxa_entrada && taxa_entrada > 0) {
		vl_parcelas_restantes = to_Number(calculaFinanciamento(entrada_total - sinal_cliente, taxa_entrada, qtde_parcelas_restantes));
	}
	else {
		vl_parcelas_restantes = to_Number(((entrada_total - sinal_cliente) / (qtde_parcelas_restantes)));
	}
	Retorno.ENTRADA_RESTANTE.entrada_valor_restante = vl_parcelas_restantes;
	Retorno.ENTRADA_RESTANTE.descricao_restante 	= `${qtde_parcelas_restantes} x ${formatNumber(vl_parcelas_restantes)}`;
	Retorno.ENTRADA_SINAL.cliente_entrada_sinal 	= to_Number(sinal_cliente);
	return Retorno;
};

const C8_calculaTotalEntrada = () => {
	let totalValor

	if (!Retorno.ENTRADA_RESTANTE.item_selecionado)
		totalValor = 	Retorno.ENTRADA_TOTAL.cliente_entrada_total
	else
		totalValor = 	Retorno.ENTRADA_SINAL.cliente_entrada_sinal +
						Retorno.ENTRADA_RESTANTE.item_selecionado.parcelas_valor_total;
	Retorno.ENTRADA_TOTAL.entrada_total_calc = totalValor;

	return Retorno;
};

const C9_calculaSaldo = (plano, imovel) => {
	let vl_entrada_total 	= Retorno.ENTRADA_TOTAL.cliente_entrada_total;
	let taxa_parcelas			= plano.planovenda_parcela_reajuste;
	let qtde_entrada;
	Retorno.ENTRADA_RESTANTE.item_selecionado ? qtde_entrada = Retorno.ENTRADA_RESTANTE.item_selecionado.parcelas_qtde : qtde_entrada = 0;
	var saldo_restante 		= to_Number(imovel.preco_final - vl_entrada_total);
	// Verifica se aplica formula dos juros no saldo das parcelas, referente ao prazo que ficou sem receber parcelas (ou durante a entrada)
	if (qtde_entrada > 0) {
		if (plano.planovenda_parcela_saldo_tipo == 'TABELA PRICE') {
			let ld_PriceComJuros = parseFloat(calculaFinanciamento(to_Number(imovel.preco_final - vl_entrada_total), taxa_parcelas, qtde_entrada)) * qtde_entrada;
			saldo_restante = ld_PriceComJuros;
		}
		else if (plano.planovenda_parcela_saldo_tipo == 'VALOR FUTURO DE INVESTIMENTO FINANCEIRO') {
			saldo_restante = (to_Number(imovel.preco_final - vl_entrada_total)) * Math.pow((1 + (taxa_parcelas / 100)), qtde_entrada);
		}
	}
	Retorno.SALDO_RESTANTE.valor = saldo_restante;
	//console.log("C9_calculaSaldo::", Retorno.SALDO_RESTANTE);
	if (plano.planovenda_formula)
		hyper1(	imovel,
						plano.planovenda_parcela_reajuste,
						Retorno.ENTRADA_TOTAL.cliente_entrada_total,
						qtde_entrada, // Verificar este valor para planos sem entrada
						Retorno.PARCELAS.item_selecionado.parcelas_qtde
					);

	return Retorno
};

const C10_calculaParcela = (plano, imovel, saldo_restante) => {
	let parcelas = []
	let lf_parcelas_financiamentos 	= 0.00;
	let taxa_parcelas 							= plano.planovenda_parcela_reajuste;
	var li_qtde_parcelas 						= plano.planovenda_parcela_quant;

	if (li_qtde_parcelas == 0) {
		parcelas.push({	parcelas_qtde : 1,
						parcelas_valor: imovel.preco_final,
						parcelas_valor_total: to_Number(imovel.preco_final),
						parcelas_descricao: 1 + " x R$ " + formatPrice(imovel.preco_final)});
	}
	else  {
		for (let i = 1; i <= li_qtde_parcelas; i++) {
			lf_parcelas_financiamentos = parseFloat(calculaFinanciamento(saldo_restante, taxa_parcelas, i))
			if (!plano.planovenda_parcela_valor_min ||
				(plano.planovenda_parcela_valor_min && plano.planovenda_parcela_valor_min > 0 && lf_parcelas_financiamentos >= plano.planovenda_parcela_valor_min))
				parcelas.push({	parcelas_qtde : i,
												parcelas_valor: lf_parcelas_financiamentos,
												parcelas_valor_total: to_Number(i * lf_parcelas_financiamentos),
												parcelas_descricao: i + " x R$ " + formatPrice(lf_parcelas_financiamentos)});
		}
	}
	Retorno.PARCELAS.itens_formapagto = parcelas;
	if (!plano.planovenda_formula)
		Retorno.PARCELAS.item_selecionado = parcelas[parcelas.length-1];
	//console.log("C10_calculaParcela", Retorno.ENTRADA_TOTAL.cliente_entrada_total);	

	return Retorno;
};

const C11_calculaTotalGeral = (imovel, parcela_selecionada) => {
	if (parcela_selecionada) {
		if (!parcela_selecionada.parcelas_valor_total)
		  parcela_selecionada.parcelas_valor_total = 0;
    Retorno.TOTAIS.total_contrato	= parcela_selecionada.parcelas_valor_total +
                                    Retorno.ENTRADA_TOTAL.entrada_total_calc
  }
	else
		Retorno.TOTAIS.total_contrato	= imovel.preco_final;

	return Retorno;
};

//////// ========================================================================================================================================================
//////// ========================================================================================================================================================
//////// ========================================================================================================================================================
//////// ========================================================================================================================================================
//////// ========================================================================================================================================================
//////// ========================================================================================================================================================

const C12_calculaParcelaSelecionada = async (plano, imovel, cliente_entrada_total, parcela_numero) => {
	let lf_parcelas_financiamentos 	= 0.00;
	let parcelas										= {};

	// if (!plano.planovenda_parcela_valor_min ||
	// 	(plano.planovenda_parcela_valor_min && plano.planovenda_parcela_valor_min > 0 && lf_parcelas_financiamentos >= plano.planovenda_parcela_valor_min)) {
// console.log("lo_hyper::::: 1",  imovel.preco_total,
// 																plano.planovenda_parcela_reajuste,
// 																cliente_entrada_total,
// 																parcela_numero);
    
		let li_quant_entradas = 1;
		if (Retorno.ENTRADA_RESTANTE.item_selecionado && Retorno.ENTRADA_RESTANTE.item_selecionado.qtde_entrada)
			li_quant_entradas = Retorno.ENTRADA_RESTANTE.item_selecionado.qtde_entrada;

		let lo_hyper = await 	hyper1(	imovel,
																	plano.planovenda_parcela_reajuste,
																	cliente_entrada_total,
																	li_quant_entradas,
																	parcela_numero
																);
//console.log("lo_hyper::::: 2", lo_hyper);
		lf_parcelas_financiamentos	= lo_hyper.valor_parcela;
		parcelas =  {	parcelas_qtde : parcela_numero,
									parcelas_valor: lf_parcelas_financiamentos,
									parcelas_valor_total: to_Number(parcela_numero * lf_parcelas_financiamentos),
									parcelas_descricao: parcela_numero + " x R$ " + formatPrice(lf_parcelas_financiamentos)
								}
	//}
	Retorno.PARCELAS.item_selecionado = parcelas;
	//console.log("C12_calculaParcela", Retorno.PARCELAS.item_selecionado);

	return Retorno;
};

const hyper1 = async (imovel, taxa_parcela, total_entrada, quant_entradas, quant_parcelas) => {

// 	var reader = new FileReader();
// 	reader.fileName = './public/Calculos - 2.xlsx'; // file came from a input file element. file = el.files[0];
// console.log('LENDO.......')	
// 	reader.onload = function(readerEvt) {
// 			console.log(readerEvt.target.fileName);
// 	};

	// readXlsxFile('../../public/Calculos - 2.xlsx').then((rows) => {
	// 	console.log('EXCEL::::', rows);
	// 	// `rows` is an array of rows
	// 	// each row being an array of cells.
	// });

	// define the data
	// Planilha Antiga -----------------------------------
	array_planilha[3][1] 	= imovel.preco_total;					//
	array_planilha[6][1] 	= quant_parcelas;				//
	array_planilha[10][1] = total_entrada;				//

	// Planilha Nova -----------------------------------
	// array_planilha[3][1] 	= imovel.area_metro;		// B04
	// array_planilha[4][1] 	= imovel.preco_metro;		// B05
	// array_planilha[5][1] 	= imovel.preco_total;		// B06
	// array_planilha[8][1] 	= quant_entradas;			  // B09
	// array_planilha[9][1] 	= quant_parcelas;				// B10
	// array_planilha[13][1] = total_entrada;				// B14

	hf = HyperFormula.buildFromArray(array_planilha, options);

	// call getCellValue to get the calculation results
	// const ls_F1 = hfInstance.getCellValue({ col: 5, row: 1, sheet: 0 });
	// const ls_G1 = hfInstance.getCellValue({ col: 6, row: 1, sheet: 0 });
	// const ls_H1 = hfInstance.getCellValue({ col: 7, row: 1, sheet: 0 });
	// const ls_I1 = hfInstance.getCellValue({ col: 8, row: 1, sheet: 0 });
	// const ls_J1 = hfInstance.getCellValue({ col: 9, row: 1, sheet: 0 });
	// const ls_K1 = hfInstance.getCellValue({ col: 10, row: 1, sheet: 0 });
	// const ls_L1 = hfInstance.getCellValue({ col: 11, row: 1, sheet: 0 });
	// const ls_M1 = hfInstance.getCellValue({ col: 12, row: 1, sheet: 0 });
	// const ls_N1 = hfInstance.getCellValue({ col: 13, row: 1, sheet: 0 });
	// const ls_O1 = hfInstance.getCellValue({ col: 14, row: 1, sheet: 0 });
	// const ls_P1 = hfInstance.getCellValue({ col: 15, row: 1, sheet: 0 });
	// const ls_Q1 = hfInstance.getCellValue({ col: 16, row: 1, sheet: 0 });
	// const ls_R1 = hfInstance.getCellValue({ col: 17, row: 1, sheet: 0 });
	//console.log(ls_F1,ls_G1,ls_H1,ls_I1,ls_J1,ls_K1,ls_L1,ls_M1,ls_N1,ls_O1,ls_P1,ls_Q1,ls_R1);
	
	// Planilha Antiga -----------------------------------------------------------------------
	const ls_C14 = hf.getCellValue({ col: 7, row: 3, sheet: 0 });	// Valor do Lote
	const ls_C15 = hf.getCellValue({ col: 7, row: 4, sheet: 0 });	// Saldo Parcelas
	const ls_C16 = hf.getCellValue({ col: 7, row: 5, sheet: 0 });	// Valor Parcelas
	const ls_C17 = hf.getCellValue({ col: 7, row: 6, sheet: 0 });	// Total Contrato
	Retorno.TOTAIS.preco_unidade = ls_C14;
	Retorno.TOTAIS.preco_final   = ls_C14;
	Retorno.SALDO_RESTANTE.valor = ls_C15;
	return {preco_final			: ls_C14, 
					valor_entrada		:	0,
					saldo						:	ls_C15,
					valor_parcela		:	ls_C16,
					total_contrato	: ls_C17
					};

	// Planilha Nova -------------------------------------------------------------------------
	// const ls_H04 = hf.getCellValue({ col: 7, row: 3, sheet: 0 });	// Valor do Lote
	// const ls_H05 = hf.getCellValue({ col: 7, row: 4, sheet: 0 });	// Valor Parcelas de Entrada
	// const ls_H06 = hf.getCellValue({ col: 7, row: 5, sheet: 0 });	// Saldo Parcelas
	// const ls_H07 = hf.getCellValue({ col: 7, row: 6, sheet: 0 });	// Valor Parcelas Mensais
	// const ls_H08 = hf.getCellValue({ col: 7, row: 7, sheet: 0 });	// Total Contrato
	// Retorno.TOTAIS.preco_unidade = ls_H04;
	// Retorno.SALDO_RESTANTE.valor = ls_H06;
	// return {preco_final			: ls_H04, 
	// 				valor_entrada		:	ls_H05,
	// 				saldo						:	ls_H06,
	// 				valor_parcela		:	ls_H07,
	// 				total_contrato	: ls_H08
	// 				};

	// Dias de vencimento das parcelas
	// Retorno.PARCELAS.opcoes_dia_vencto = [];
	// Retorno.PARCELAS.opcoes_dia_vencto[0] = hf.getCellValue({ col: 7, row: 15, sheet: 0 });	// 1 opção de dia vencto
	// Retorno.PARCELAS.opcoes_dia_vencto[1] = hf.getCellValue({ col: 7, row: 16, sheet: 0 });	// 2 opção de dia vencto
	// Retorno.PARCELAS.opcoes_dia_vencto[2] = hf.getCellValue({ col: 7, row: 17, sheet: 0 });	// 3 opção de dia vencto
	// Retorno.PARCELAS.opcoes_dia_vencto[3] = hf.getCellValue({ col: 7, row: 18, sheet: 0 });	// 4 opção de dia vencto
	// Retorno.PARCELAS.opcoes_dia_vencto[4] = hf.getCellValue({ col: 7, row: 19, sheet: 0 });	// 5 opção de dia vencto
	//console.log("Saldo restante:::", ls_H06);
		
	//console.log('HYPER 1 ::::::::', array_planilha[3][2], array_planilha[4][2], array_planilha[6][2], array_planilha[10][2]);
	//console.log('HYPER 2 ::::::::', ls_H04, ls_H05, ls_H06, ls_H07, ls_H08);
						 
};

//////// ========================================================================================================================================================
//////// ========================================================================================================================================================
//////// ========================================================================================================================================================
//////// ========================================================================================================================================================
//////// ========================================================================================================================================================

export 	{	setOrcamentoParcelas,
			generateOrcamento,
			retornaPlanoPossibilidades,
			C1_calculaPlano,
			C2_calculaEntradaMinimaInicial,
			C3_calculaClienteEntradaTotal,
			C4_calculaEntradaSinalInicial,
			C5_calculaEntradaParcelasRestantesItens,
			C6_calculaEntradaParcelasRestantesInicial,
			C7_calculaEntradaParcelasRestantesCliente,
			//calculaValores,
			//calculaEntradaSinal,
			//calculaClienteEntradaTotal,
			//calculaEntradaParcelasRestantes,
			C8_calculaTotalEntrada,
			C9_calculaSaldo,
			C10_calculaParcela,
			C11_calculaTotalGeral,
			C12_calculaParcelaSelecionada,

			hyper1,

		};
